.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

a {
  color: rgb(74, 195, 181);
  text-decoration: none;
}
